import { $ } from '@situation/setdesign.util';
import hideOverflowItems from './hideOverflowItems';
import { calculateTotalWidth, hasResized, CLASS_HIDDEN } from './util';
import setupOverflowToggle from './setupOverflowToggle';

const handleOverflow = () => {
    if (!hasResized()) {
        return;
    }

    $('.sub-nav--overflow-buttons').each((index, container) => {
        const $container = $(container);
        $container.find('.sub-nav__item--more').remove();
        const $list = $container.find('.sub-nav__list');
        const $items = $container.find('.sub-nav__item').removeClass(CLASS_HIDDEN);
        if ($items.length > 3) {
            $container.addClass('sub-nav--xl');
        }

        const containerWidth = Math.floor($list.outerWidth());
        const totalItemsWidth = Math.floor(calculateTotalWidth($items));
        if (containerWidth < totalItemsWidth) {
            hideOverflowItems($list, $items, containerWidth, totalItemsWidth);
            setupOverflowToggle($list);
        }
    });
};

export default handleOverflow;
