import { $ } from '@situation/setdesign.util';

const CLASS_HIDDEN = 'sub-nav__item--hidden';
const calculateTotalWidth = ($items) => $items.toArray().reduce((t, i) => t + $(i).outerWidth(), 0);
const MORE_HTML = '<span class="icon icon-more-dots"><svg><use xlink:href="#icon-more-dots"></use></svg></span>';
const createMoreDotsElement = () => $('<li class="sub-nav__item sub-nav__item--more">').html(
    `<a href="#" class="sub-nav__link">${MORE_HTML}</a><ul class="sub-nav__expand">PLACEHOLDER - THIS WILL CONTAIN THE LIST OF ELEMENTS WE HAVE HIDDEN FROM THE MAIN LIST!</ul>`,
);
let viewportWidth = 0;

const hasResized = () => {
    const newViewportWidth = SetDesign.viewport.width();
    if (newViewportWidth !== viewportWidth) {
        viewportWidth = newViewportWidth;
        return true;
    }
    return false;
};

export {
    CLASS_HIDDEN, calculateTotalWidth, createMoreDotsElement, hasResized,
};
