import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    $('.nav-links .nav-previous a').addClass('link-arrow');
    $('.nav-links .nav-next a').addClass('link-arrow link-arrow--left');
    $('a.link-arrow').each((i, el) => {
        const $link = $(el);
        $link.find('.icon').remove();
        const content = $(`<span class="link-arrow__content">${$link.html().trim()}</span>`);
        const icon = $(
            '\n            <span class="icon icon-long-arrow" aria-hidden="true">\n                <svg><use xlink:href="#icon-long-arrow"></use></svg>\n            </span>\n        ',
        );
        $link.html(
            $link.hasClass('link-arrow--left')
                ? [icon.addClass('icon-direction-up'), content]
                : [content, icon],
        );
    });
});
