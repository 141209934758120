import { $, addAction, INIT } from '@situation/setdesign.util';
import gtmInteraction from '../src/gtm-interaction';

addAction(INIT, () => {
    const $selector = $('.header__select-city select');
    $selector.click((e) => {
        gtmInteraction(e.currentTarget);
    });

    $selector.change(() => {
        let selectedCity = '';
        $('.header__select-city select option:selected').each((index, element) => {
            selectedCity += $(element).attr('value');
            gtmInteraction(element);
        });

        window.location.href = selectedCity;
    });
});
