import { $, doAction, addAction } from '@situation/setdesign.util';

let idIndex = 0;
class SeatingChartContentSection {
    constructor(elem) {
        this.component = $(elem);
        this.id = this.component.attr('id') || `seating-chart-content-section-${idIndex}`;
        idIndex += 1;
    }

    initialize() {
        this.lists = this.component.find('.seating-sections-list');
        this.chart = this.component.find('.seating-chart');
        this.dynamicPrices = this.component.find('[data-dynamic-price]');
        addAction('seating-sections-list.item.selected', this.onListItemSelected.bind(this));
        addAction('seating-chart.section.selected', this.onChartSectionSelected.bind(this));
        addAction('seating-chart.loaded', this.onSeatingChartLoaded.bind(this));
        this.updateDynamicPriceField();
    }

    onListItemSelected(arg) {
        if (this.lists.filter(`[id="${arg.id}"]`).length) {
            doAction('seating-chart.update.selected', {
                id: this.chart.attr('id'),
                value: arg.value,
            });

            this.updateDynamicPriceField(arg.value);
        }
    }

    onChartSectionSelected(arg) {
        if (arg.id === this.chart.attr('id')) {
            doAction('seating-sections-list.update.selected', {
                ids: this.lists.map((i, el) => $(el).attr('id')).get(),
                value: arg.value,
            });
        }
    }

    onSeatingChartLoaded() {
        const values = this.lists.data('values');
        doAction('seating-chart.valid-paths', { id: this.chart.attr('id'), value: values });
    }

    updateDynamicPriceField(sectionID) {
        this.dynamicPrices.removeClass('show');

        if (sectionID) {
            this.dynamicPrices
                .filter((i, el) => $(el).data('dynamicPrice') === sectionID)
                .addClass('show');
        }
    }
}

export default SeatingChartContentSection;
