import { $, doAction } from '@situation/setdesign.util';

class ShowFilter {
    constructor(elem) {
        this.component = $(elem);
        this.wrapper = this.component.find('.show-filters__wrapper');
        this.refineButton = this.component.find('.show-filters__refine-button');
    }

    initialize() {
        this.component.find('input').on('change', this.onFilterChanged.bind(this));
        this.refineButton.on('click', this.onRefineButtonClick.bind(this));
        this.onFilterChanged();
    }

    onFilterChanged() {
        const filter = {};
        this.component.find('[data-filter-group]').each((index, elem) => {
            const group = $(elem);
            const label = group.attr('data-filter-group');
            const checkedInputs = group.find('input:checked');
            filter[label] = [];
            checkedInputs.each((i, el) => {
                const input = $(el);
                filter[label].push(input.val());
            });
        });

        doAction('show-filters.changed', filter);
    }

    onRefineButtonClick() {
        if (this.component.hasClass('expanded')) {
            this.wrapper.slideUp();
        } else {
            this.wrapper.slideDown();
        }

        this.component.toggleClass('expanded');
    }

    static init(elem) {
        const instance = new ShowFilter(elem);
        instance.initialize();
        return instance;
    }
}

export default ShowFilter;
