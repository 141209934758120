import { $, doAction, addAction } from '@situation/setdesign.util';

let idIndex = 0;
class SeatingChart {
    constructor(elem) {
        this.component = $(elem);
        this.component.attr('id', this.component.attr('id') || `seating-chart-${idIndex}`);
        this.id = this.component.attr('id');
        this.image = this.component.find('img.seating-chart__svg');
        idIndex += 1;
    }

    initialize() {
        const imgURL = this.image.attr('src');
        $.get(imgURL, this.getSvgHandler.bind(this), 'xml');
        // Observe click on svg paths
        this.component.on('click', 'svg path[id]:not(#seats)', this.onSectionClick.bind(this));
        // Listen for selected update events
        addAction('seating-chart.update.selected', this.onUpdateSelectedEvent.bind(this));
        addAction('seating-chart.valid-paths', this.updateValidPaths.bind(this));
    }

    getSvgHandler(data) {
        const imgID = this.image.attr('id');
        const imgClass = this.image.attr('class');
        // Get the SVG tag, ignore the rest
        let svg = $(data).find('svg');
        // Add replaced image's ID to the new SVG
        if (typeof imgID !== 'undefined') {
            svg = svg.attr('id', imgID);
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== 'undefined') {
            svg = svg.attr('class', `${imgClass} replaced-svg`);
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        svg = svg.removeAttr('xmlns:a');

        // Check if the viewport is set, if the viewport is not set the SVG won't scale.
        if (!svg.attr('viewBox') && svg.attr('height') && svg.attr('width')) {
            svg.attr('viewBox', `0 0 ${svg.attr('height')} ${svg.attr('width')}`);
        }

        // Replace image with new SVG
        this.image.replaceWith(svg);
        doAction('seating-chart.loaded');
    }

    selectSections(sections) {
        const allSections = Array.isArray(sections)
            ? sections
            : sections.split(',').map((s) => s.trim());
        this.component
            .find('path')
            .removeClass('selected')
            .filter((index, el) => allSections.includes(el.id))
            .addClass('selected');

        this.component.find('svg').attr('data-selected', allSections.join(','));
    }

    onSectionClick(event) {
        doAction('seating-chart.section.selected', {
            id: this.id,
            value: $(event.target).attr('id'),
        });
    }

    // topic = seating-sections-list.update.selected, arg = { id: string, value: string }
    onUpdateSelectedEvent(arg) {
        if (arg.id === this.id) {
            this.selectSections(arg.value);
        }
    }

    updateValidPaths(arg) {
        if (arg.id === this.id) {
            this.component.find('path').each((i, el) => {
                const $el = $(el);
                $el.toggleClass('invalid-path', !arg.value.includes($el.attr('id')));
            });
        }
    }
}

export default SeatingChart;
