import { doAction } from '@situation/setdesign.util';
import { CLASS_HIDDEN } from './util';

const setupOverflowToggle = ($list) => {
    const $hiddenElements = $list.find(`.${CLASS_HIDDEN}`).clone().removeClass(CLASS_HIDDEN);
    const $moreTrigger = $list.find('.sub-nav__item--more').children('.sub-nav__link');
    const $expandContainer = $list.find('.sub-nav__expand');
    $expandContainer.empty().append($hiddenElements);
    $moreTrigger.on('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
        const hiding = $expandContainer.hasClass('sub-nav__expand--show');
        if (!hiding) {
            doAction('closeAllExpandedSubnav');
        }

        $expandContainer.toggleClass('sub-nav__expand--show', !hiding);
    });
};

export default setupOverflowToggle;
