import { $, addAction, doAction } from '@situation/setdesign.util';

const attributeFilter = (needles, haystack) => (index, elem) => {
    const values = ($(elem).attr(haystack) || '').split(',');
    const matches = values.some((v) => needles.indexOf(v) >= 0);
    return !matches;
};

class EngagementListing {
    constructor(elem) {
        this.component = $(elem);
        this.cards = this.component.find('.engagement-card');
        this.empty = this.component.find('.engagement-card-list__empty');
    }

    initialize() {
        if (!this.component.hasClass('disable-filter')) {
            addAction('show-filters.changed', this.onFilterChanged.bind(this));
        }
    }

    onFilterChanged(filter) {
        // Reset all shows before filtering
        this.cards.show();

        if (filter.series && filter.series.length) {
            const series = String(filter.series[0]);
            if (series.toLowerCase() === 'all-shows') {
                this.cards.show();
            } else {
                this.cards
                    .filter(attributeFilter(filter.series, 'data-engagement-filter-series'))
                    .hide();
            }
        }
        if (filter.categories && filter.categories.length) {
            this.cards
                .filter(attributeFilter(filter.categories, 'data-engagement-filter-categories'))
                .hide();
        }
        if (filter.themes && filter.themes.length) {
            this.cards
                .filter(attributeFilter(filter.themes, 'data-engagement-filter-themes'))
                .hide();
        }
        if (this.component.find('.engagement-card:visible').length) {
            this.empty.hide();
        } else {
            this.empty.show();
        }

        doAction('refreshLazyLoad', this.component);
    }

    static init(elem) {
        const instance = new EngagementListing(elem);
        instance.initialize();
        return instance;
    }
}

export default EngagementListing;
