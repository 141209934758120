import { $, addAction, addFilter } from '@situation/setdesign.util';

addFilter('content-slider/owl-settings', (settings) => ({
    ...settings,
    margin: 5,
    dots: true,
    navText: [
        '<span class="icon icon-caret-right"><svg><use xlink:href="#icon-caret-right"></use></svg></span>',
        '<span class="icon icon-caret-right"><svg><use xlink:href="#icon-caret-right"></use></svg></span>',
    ],
}));

const captionToggle = ($toggle, $caption) => {
    if (!$toggle || !$caption) {
        return;
    }

    $toggle.click(() => {
        $toggle.parent().toggleClass('figure--caption-active');
    });
};

const wrapDotsArrows = ($nav, $dots) => {
    if (!$nav || !$dots) {
        return;
    }

    $nav.add($dots).wrapAll('<div class="owl-interactions-wrapper"></div>');
};

const setDotVisibility = (position, $dots, visibleRange = 5) => {
    const halfRange = Math.floor(visibleRange / 2);
    const totalDots = $dots.length;
    let start = Math.max(0, position - halfRange);
    const end = Math.min(totalDots - 1, start + visibleRange - 1);
    // Adjust start if we're near the end
    if (end === totalDots - 1) {
        start = Math.max(0, end - visibleRange + 1);
    }

    $dots.each((index, dot) => {
        $(dot).toggleClass('owl-dot--hidden', index < start || index > end);
    });
};

addAction('content-slider/init', ($slider) => {
    const $captionToggle = $slider.find('.figure__caption-toggle');
    const $caption = $slider.find('.figure-caption');
    const $owlNav = $slider.find('.owl-nav');
    const $owlDotsContainer = $slider.find('.owl-dots');
    const $owlDots = $slider.find('.owl-dot');
    captionToggle($captionToggle, $caption);
    setDotVisibility(0, $owlDots);
    $slider.on('changed.owl.carousel', (event) => {
        if (event.property.name === 'position') {
            setDotVisibility(event.item.index, $owlDots);
        }
    });

    if ($owlNav.length && $owlDots.length) {
        wrapDotsArrows($owlNav, $owlDotsContainer);
    }
});
