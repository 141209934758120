import { $, INIT, addAction } from '@situation/setdesign.util';
import SeatingChart from '../src/SeatingChart';
import SeatingChartContentSection from '../src/SeatingChartContentSection';
import SeatingSectionsList from '../src/SeatingSectionsList';

addAction(INIT, () => {
    $('.seating-sections-list').each((i, el) => {
        new SeatingSectionsList(el).initialize();
    });

    $('.seating-chart').each((i, el) => {
        new SeatingChart(el).initialize();
    });

    $('[data-seating-container]').each((i, el) => {
        new SeatingChartContentSection(el).initialize();
    });
});
