import { $, addAction } from '@situation/setdesign.util';

const eventHandlers = () => {
    const hideSubNav = () => {
        $('.sub-nav__expand--show').removeClass('sub-nav__expand--show');
    };

    $(document).on('click', hideSubNav);
    addAction('key.esc', hideSubNav);
    addAction('closeAllExpandedSubnav', hideSubNav);
};

export default eventHandlers;
