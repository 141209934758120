import { $ } from '@situation/setdesign.util';
import { createMoreDotsElement, CLASS_HIDDEN } from './util';

const hideOverflowItems = ($list, $items, containerWidth, totalItemsWidth) => {
    const $moreDots = createMoreDotsElement().appendTo($list);
    let widthNeeded = totalItemsWidth + $moreDots.outerWidth();
    $($items.get().reverse()).each((i, item) => {
        const $item = $(item);
        widthNeeded -= $item.outerWidth();
        $item.addClass(CLASS_HIDDEN);
        return containerWidth < widthNeeded; // Break when target width is reached
    });
};

export default hideOverflowItems;
