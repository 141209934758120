import { $, addAction, doAction } from '@situation/setdesign.util';

let idIndex = 0;
const itemSelector = '.seating-sections-list__select';
class SeatingSectionsList {
    constructor(elem) {
        this.component = $(elem);
        this.component.attr('id', this.component.attr('id') || `seating-sections-list-${idIndex}`);
        this.id = this.component.attr('id');
        this.list = this.component.find('ul');
        idIndex += 1;
    }

    initialize() {
        this.generateMobileSelect();
        this.setValueList();
        this.component.on('click', itemSelector, (event) => {
            const $listitem = $(event.currentTarget).parents('li');
            this.onItemSelected($listitem.attr('data-value'), $listitem.attr('data-label'));
        });

        addAction('seating-sections-list.update.selected', this.onUpdateSelectedEvent.bind(this));
        addAction(
            'seating-sections-list.related-item.selected',
            this.onRelatedSeatingSectionListItemSelectedEvent.bind(this),
        );
    }

    generateMobileSelect() {
        const placeholder = this.component.attr('data-placeholder');
        this.selectWrapper = this.component.find('.seating-sections-list__dropdown');
        this.select = this.selectWrapper.find('select');

        if (!this.selectWrapper.length) {
            const fieldId = `seating-sections-list-select--${this.id}`;
            this.selectWrapper = $('<div class="seating-sections-list__dropdown">');
            this.select = $(`<select id="${fieldId}">`);
            this.component.prepend(this.selectWrapper);
            this.selectWrapper.append(
                `<label for="${fieldId}" class="sr-only">${placeholder}</label>`,
            );

            this.selectWrapper.append(this.select);
        }

        const selected = this.select.val();
        this.select.html(placeholder ? $(`<option disabled selected>${placeholder}</option>`) : '');
        this.list.children('li').each((index, elem) => {
            const item = $(elem);
            const label = item.attr('data-label');
            const value = item.attr('data-value') || label;
            this.select.append($(`<option value="${value}">${label}</option>`));
        });

        this.select.on('change', () => {
            this.onItemSelected(this.select.val(), this.select.find('option:selected').text());
        });

        this.select.find(`option[value="${selected}"]`).prop('selected', true);
    }

    selectItem(value) {
        let realValue = value;
        this.component.find(itemSelector).removeClass('selected').attr('aria-pressed', 'false');
        this.list.find('li').each((index, elem) => {
            const $el = $(elem);
            const dataValue = $el.data('value');
            const sections = dataValue.split(',');
            value.split(',').forEach((val) => {
                if (sections.includes(val)) {
                    realValue = dataValue;
                    $el.find(itemSelector).addClass('selected').attr('aria-pressed', 'true');
                }
            });
        });

        this.select.val(realValue);
        return realValue;
    }

    onItemSelected(value, label) {
        const realValue = this.selectItem(value);
        const arg = { id: this.id, value: realValue || label };
        doAction('seating-sections-list.item.selected', arg);
        doAction('seating-sections-list.related-item.selected', arg);
    }

    // topic = seating-sections-list.update.selected, arg = { ids: array, value: string }
    onUpdateSelectedEvent(arg) {
        if (arg.ids && arg.ids.includes(this.id)) {
            this.onItemSelected(arg.value);
        }
    }

    // topic = seating-sections-list.related-item.selected, arg = { id: string, value: string }
    onRelatedSeatingSectionListItemSelectedEvent(arg) {
        if (
            arg.id !== this.id
            && this.list.find('li').filter(`[data-value="${arg.value}"]`).length
        ) {
            this.selectItem(arg.value);
        }
    }

    setValueList() {
        let values = [];
        this.list.children('li').each((index, elem) => {
            values = values.concat($(elem).data('value').split(','));
        });

        this.component.data('values', values);
    }
}

export default SeatingSectionsList;
